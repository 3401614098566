<script>
import { mapState } from "vuex";
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      "This user has no sponsored.": "Este usuário não possui nenhum indicado.",
      "The user you are trying to access does not exist or is not on your network.":
        "O usuário que está tentando acessar não existe ou não está em sua rede.",
    },
    es: {
      "This user has no sponsored.": "Este usuario no tiene nominado.",
      "The user you are trying to access does not exist or is not on your network.":
        "El usuario al que intenta acceder no existe o no está en su red.",
    },
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      id: this.$route.params.id,
      uplines: "",
      downlines: "",

      loading: true,
      empty: false,
      message: "",
    };
  },
  methods: {
    getMatriz() {
      this.loading = true;

      api.get("network/matriz/" + this.id).then((response) => {
        if (response.data.status == "success") {
          this.downlines = response.data.downlines;
          this.uplines = response.data.uplines;
        } else {
          this.empty = true;
          this.message = response.data.message;
        }

        this.loading = false;
      });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    } else {
      this.id = this.account.user.id;
    }

    this.getMatriz();
  },
  watch: {
    "$route.params.id": function (id) {
      if (id) {
        this.id = id;
      } else {
        this.id = this.account.user.id;
      }

      this.getMatriz();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex">
          <h4 class="mb-0 font-size-18">{{ t("Matriz") }}</h4>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mb-4">
      <div class="col-lg-6">
        <div class="m-0" v-for="(up, index) in uplines" :key="index">
          <div
            class="card mb-1"
            :class="{ 'bg-soft-dark': uplines.length == index + 1 }"
          >
            <div class="card-body px-2 py-1">
              <router-link
                :to="'/network/matriz/' + up.id"
                class="d-flex align-items-center"
              >
                <img
                  v-if="up.avatar"
                  :src="up.avatar"
                  class="rounded-circle header-profile-user"
                />
                <img
                  v-else
                  src="@/assets/images/graduations/pin.png"
                  class="rounded-circle header-profile-user"
                />
                <span class="flex-fill ml-2 text-dark">{{ up.name }}</span>
                <span class="ml-3 text-dark"
                  ><i class="bx bx-chevron-up font-size-22 align-middle"></i
                ></span>
              </router-link>
            </div>
          </div>
        </div>

        <div class="text-center py-4 font-size-12">
          <i class="bx bx-sort-down font-size-18 align-middle"></i> INDICADOS
        </div>

        <div v-if="loading == true" class="text-center">
          <div class="card">
            <div class="card-body">
              <b-spinner
                small
                class="ml-2 align-middle"
                variant="white"
                role="status"
              ></b-spinner>
            </div>
          </div>
        </div>
        <div v-else-if="downlines.length == 0" class="text-center">
          <div class="card">
            <div class="card-body">
              {{ t("This user has no sponsored.") }}
            </div>
          </div>
        </div>
        <div v-else-if="empty" class="text-center">
          <div class="card">
            <div class="card-body">
              {{ t(message) }}
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="(dow, index) in downlines" :key="index">
            <router-link :to="'/network/matriz/' + dow.id">
              <div class="card mb-1">
                <div class="card-body d-flex align-items-center px-2 py-1">
                  <img
                    v-if="dow.avatar"
                    :src="dow.avatar"
                    class="rounded-circle header-profile-user"
                    style="width: 40px; height: 40px"
                  />
                  <img
                    v-else
                    src="@/assets/images/graduations/pin.png"
                    class="rounded-circle header-profile-user"
                    style="width: 40px; height: 40px"
                  />
                  <span class="flex-fill ml-2 text-dark">{{
                    dow.name
                  }}</span>
                  <span class="ml-2 text-dark"
                    ><mark>{{ dow.sponsored }}</mark></span
                  >
                  <span class="ml-3 text-dark"
                    ><i
                      class="bx bx-chevron-right font-size-22 align-middle"
                    ></i
                  ></span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>